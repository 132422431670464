<template>
  <div class="cs-main">
    <div class="g1">
       <ImageWide
        :image="'sketch'"
        :alt="'image of card with sketch art example'"
      />
      <ImageWide
        :image="'colored'"
        :alt="'image of card with colored art example'"
      />
      <ImageWide
        :image="'lineart'"
        :alt="'image of card with lineart art example'"
      />
      <ImageWide
        :image="'cellshade'"
        :alt="'image of card with cellshade art example'"
      />
    </div>

    <div class="g2">
       <ImageSwiper
        :backgroundImage="'swiper_styles_bg'"
        :backgroundAlt="'image set background'"
        :images="styleData"
      />
    </div>

    <div class="g3">
      <ImageWide
        :image="'alts'"
        :alt="'image of card with alts example'"
      />
    </div>

    <div class="g4">
       <ImageSwiper
        :backgroundImage="'swiper_backgrounds_bg'"
        :backgroundAlt="'image set background'"
        :images="backgroundData"
      />
    </div>

    <div class="g5">
      <ImageWide
        :image="'doanddont'"
        :alt="'image of card with do\'s and dont\'s example'"
      />
    </div>
   
  </div>
</template>

<script>
import ImageWide from '@/components/ImageWide.vue'
import ImageSwiper from '@/components/ImageSwiper.vue'

export default {
  name: 'ContentStyles',

   components: {
        ImageWide, ImageSwiper
    },

  data() {
    return {
      styleData: [
        { filename: 'examples_sketch', displayName: 'SKETCH', preloadedImage: null, alt: 'three image examples of sketch style'},
        { filename: 'examples_colored', displayName: 'COLORED', preloadedImage: null , alt: 'three image examples of colored style'},
        { filename: 'examples_lineart', displayName: 'LINE ART', preloadedImage: null , alt: 'three image examples of lineart style'},
        { filename: 'examples_cellshade', displayName: 'CELL SHADE', preloadedImage: null , alt: 'three image examples of cell shade style'},
      ],
      backgroundData: [
        { filename: 'backgrounds_1', displayName: null, preloadedImage: null, alt: 'three image examples of backgrounds'},
        { filename: 'backgrounds_2', displayName: null, preloadedImage: null , alt: 'three image examples of backgrounds'},
      ]
    }
  },

  props: {

  },

  methods: {

  },
}
</script>


<style scoped>

.cs-main, .cs-g1, .cs-g2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.g1, .g2, .g3, .g4, .g5 {
  margin-top: 10%;
}

</style>
