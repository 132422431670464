<template>
  <div class="cm-main">
    <ImageInfo/>
  </div>
</template>

<script>
import ImageInfo from '@/components/ImageInfo.vue'

export default {
  name: 'InfoMenu',

   components: {
        ImageInfo
    },

  data() {
    return {
      
    }
  },

  props: {

  },

  methods: {

  },
}
</script>


<style scoped>

.cm-main, .cm-tabs, .cm-menus {
  width: 100%;
  display: flex;
}

</style>
