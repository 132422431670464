<template>
  <div class="swiper">
     <img class="background" :src="require(`@/assets/${backgroundImage}.webp`)" :alt="backgroundAlt">
     <div class="overlay">
        <div class="r1"></div>
        <div class="r2">
          <div class="c1">
            <div class="arrow-container arrow-left-container" @click="PrevImage">
              <img class="arrow-left arrow-purple" :src="require(`@/assets/arrow_purple.webp`)" alt="clickable purple arrow pointing to the left">
              <img class="arrow-left arrow-pink" :src="require(`@/assets/arrow_pink.webp`)" alt="clickable pink arrow pointing to the left">
            </div>
          </div>
          <div class="c2">
            <img
              v-for="(item, index) in images"
              :key="index"
              class="inactive"
              :class="{ 'active': item.isActive }"
              :src="item.preloadedImage"
              :alt="item.alt"
              @touchstart="TouchStart"
              >
          </div>
          <div class="c3">
            <div class="arrow-container arrow-right-container" @click="NextImage">
              <img class="arrow-right arrow-purple" :src="require(`@/assets/arrow_purple.webp`)" alt="clickable purple arrow pointing to the left">
              <img class="arrow-right arrow-pink" :src="require(`@/assets/arrow_pink.webp`)" alt="clickable pink arrow pointing to the left">
            </div>
          </div>
        </div>
        <div class="r3">
          <p class="text display-text">{{images[activeGroup].displayName}}</p>
        </div>
     </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSwiper',

  props: {
    backgroundImage: String,
    backgroundAlt: String,
    images: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activeGroup: 0,
    }
  },

  mounted() {

    this.images.forEach(element => {
      
      element.preloadedImage = require(`@/assets/${element.filename}.webp`);
      element.isActive = false

    });

    this.images[0].isActive = true
  },

   methods: {

    NextImage() {
      this.images[this.activeGroup].isActive = false

      this.activeGroup++;

      if (this.activeGroup >= this.images.length)
      {
        this.activeGroup = 0;
      }

      this.images[this.activeGroup].isActive = true
    },

     PrevImage() {

      this.images[this.activeGroup].isActive = false

      this.activeGroup--;

      if (this.activeGroup < 0)
      {
        this.activeGroup = (this.images.length - 1);
      }

      this.images[this.activeGroup].isActive = true
    },

    TouchStart (touchEvent) {
        // We only care if one finger is used
        if (touchEvent.changedTouches.length !== 1)
        { 
          return;
        }

        const posXStart = touchEvent.changedTouches[0].clientX;
        addEventListener('touchend', (touchEvent) => this.TouchEnd(touchEvent, posXStart), {once: true});
      },

    TouchEnd (touchEvent, posXStart) {

        // We only care if one finger is used
        if (touchEvent.changedTouches.length !== 1)
        { 
          return;
        }

        const posXEnd = touchEvent.changedTouches[0].clientX;

        if (posXStart < posXEnd)
        {
          // Swipe right:
          this.PrevImage()
        }
        else if (posXStart > posXEnd)
        {
          // Swipe left:
          this.NextImage()
        }
      }




  },
}
</script>


<style scoped>

.swiper, .background {
  position: relative;
}

.background {
  display: block;
}

.overlay, .r1, .r2, .r3 {
  display: flex;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.r1 {
  height: 16%;
}

.r2 {
  height: 64%;
}

.r3 {
  height: 20%;
}

.c1, .c3 {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c2 {
  width: 80%;
}


.arrow-left {
  transform: scaleX(-1);
}

.arrow-right {
  transform: scaleX(1);
}

.arrow-container {
  position: relative;
  width: 100%;
}

.arrow-left-container {
  display: flex;
  justify-content: center;
}

.arrow-right-container {
  display: flex;
  justify-content: center;
}

.arrow-container img {
  width: 80%;
  max-width: 106px;
}

.arrow-purple {
  position: relative;
}

.arrow-pink {
  opacity: 0;
  position: absolute;
}

.arrow-pink:hover {
  opacity: 1;
}

.c2 {
  position: relative;
}

.c2 img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
}

.inactive {
  opacity: 0;
}

.active {
  opacity: 1;
}

.r3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: #7a87cd;
}




</style>
