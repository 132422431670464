<template>
  <div class="cs-main">
    <div class="g1">
      <ImageWide
        :image="'stickers_prices'"
        :alt="'stickers with prices'"
      />
    </div>

    <div class="g2">
      <ImageWide
        :image="'stickers_info'"
        :alt="'stickers description'"
      />
    </div>

    <div class="g3">
      <ImageCustom
        :backgroundImage="'stickers_extra'"
        :alt="'sticker types'"
        :images="imagesData"
      />
    </div>

    <div class="g4">
      <PackageZone
        :backgroundImage="'stickers_package_bg'"
        :alt="'clickable pack examples with link to the telegram sticker pack'"
      />
    </div>


    <div class="g5">
     
    </div>
   
  </div>
</template>

<script>
import ImageWide from '@/components/ImageWide.vue'
import ImageSwiper from '@/components/ImageSwiper.vue'
import ImageCustom from '@/components/ImageCustom.vue'
import PackageZone from '@/components/PackageZone.vue'

export default {
  name: 'ContentStyles',

   components: {
        ImageWide, ImageSwiper, ImageCustom, PackageZone
    },

  data() {
    return {
      imagesData: [
        { filename: 'stickers_meme1', alt: 'image of meme sticker', active: true},
        { filename: 'stickers_meme2', alt: 'image of meme sticker', active: false},
        { filename: 'stickers_meme3', alt: 'image of meme sticker', active: false},
        { filename: 'stickers_meme4', alt: 'image of meme sticker', active: false},
        { filename: 'stickers_meme5', alt: 'image of meme sticker', active: false},
      ],
    }
  },

  props: {

  },

  methods: {

  },
}
</script>


<style scoped>

.cs-main, .cs-g1, .cs-g2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.g1, .g2, .g3, .g4, .g5 {
  margin-top: 10%;
}

</style>
