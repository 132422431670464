<template>
  <div class="ba-main">
    <img :src="require(`@/assets/${image}.webp`)" alt="website banner">
    <div class="ba-menu">
      <p class="ba-text display-text" :class="{'active-tab': currentTab === 'info'}" @click="SetActiveTab('info')">INFO</p>
      <p class="ba-text display-text" :class="{'active-tab': currentTab === 'prices'}"  @click="SetActiveTab('prices')">PRICES</p>
      <p class="ba-text display-text" :class="{'active-tab': currentTab === 'tos'}"  @click="SetActiveTab('tos')">TOS</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',

  data() {
    return {
    }
  },

  props: {
    image: String,
    alt: String,
    currentTab: String
  },

  emits: ["activeTabChanged"],

  methods: {
    SetActiveTab(tab) {
      this.$emit("activeTabChanged", tab)
    }
  },
}
</script>


<style scoped>

.ba-main {
  width: 100%; 
  position: relative;
}

img {
  position: relative;
}

.ba-menu {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.ba-text {
  font-weight: 500;
  color: #515aa9;
  transition: 0.4s ease-in-out;
}

.ba-text:hover {
  color: #b75371;
  cursor: pointer;
}

.active-tab {
  color: #b75371;
}

@media (width >= 1080px) and (width < 3840px) {
  .display-text {
    font-size: clamp(2em, 4vw, 2.00em)
  }
}

</style>
