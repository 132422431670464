<template>
  <div class="container">
    <div class="box" @click="OpenLink(images.telegramLink)">
      <div class="top">
        <div class="image-container">
           <img
            v-for="(filename, index) in images.filenames"
            :key="index"
            class="sticker"
            :class="{'active-image': currentImage === index}" 
            :src="require(`@/assets/${filename}.webp`)"
            :alt="images.alt"
          >
        </div>
      </div>
      <div class="bot">
        <div class="species"><p>{{ images.species }}</p></div>
        <div class="name"><p>{{ images.name }}</p></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickerPackage',

  props: {
    backgroundImage: String,
    alt: String,
    images: Object
  },

   data() {
    return {
      currentImage: 0,
      intervalId: null,
    }
  },

  mounted() {
    this.intervalId = setInterval(this.SwitchImage, 4000);
  },

  beforeUnmount() {
    clearInterval(this.intervalId);
  },

  methods: {
    SwitchImage() {
      this.currentImage++;

      if (this.currentImage >= this.images.filenames.length)
      {
        this.currentImage = 0;
      }
    },

    OpenLink(link) {
      window.open(link, '_blank');
    }
  },
}
</script>


<style scoped>

.container {
  max-width: 420px;
  max-height: 550px;
  margin: 10px;
}

.box {
  background-image: linear-gradient(#eec1e2, #ffc6dd);
  border-radius: 8%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.box:hover {
  transform: scale(1.1);
}

.top {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  width: 80%;
  height: 85%;
  position: relative;
}

.sticker {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.active-image {
  opacity: 1;
  z-index: 5;
}
 
.bot {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.species {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: -4%;
}

.name {
  color: #ea67af;
  font-weight: bold;

}


@media (width >= 300px) and (width < 640px) {
  .species {
    font-size: 2.2vw;
  }

  .name {
    font-size: 2.5vw;
  }
}

@media  (width >= 640px) and (width < 1080px) {
  .species {
    font-size: 2.7vw;
  }

  .name {
    font-size: 3.0vw;
  }
}

@media (width >= 1080px) and (width < 3840px) {
  .species {
    font-size: clamp(1.15em, 1.5vw, 1.60em)
  }

  .name {
    font-size: clamp(1.15em, 1.5vw, 1.60em)
  }
}

@media (width >= 3840px) and (width < 7680px) {
  .species {
    font-size: clamp(1.15em, 1.5vw, 1.60em)
  }

  .name {
    font-size: clamp(1.15em, 1.5vw, 1.60em)
  }
}




</style>
