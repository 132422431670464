<template>
  <div class="container">
    <div class="g1">
      <ImageWide
        :image="'sheets1'"
        :alt="'reference sheet example with customization'"
      />
      <button class="g1-button button-text" @click="goto('examples')">
        EXAMPLES
      </button>
    </div>
    <div class="g2">
      <ImageWide
        :image="'sheets2'"
        :alt="'reference sheet example with prices'"
      />
    </div>
    <div class="g3">
      <ImageWide
        :image="'sheets3'"
        :alt="'reference sheet example with characte stories'"
      />
    </div>
    <div class="g4">
      <ImageWide
        :image="'sheets4'"
        :alt="'reference sheet example with extras'"
      />
    </div>
    <div class="g5" ref="examples">
      <SheetExampleSwiper
        :backgroundImage="'ref_bg'"
        :backgroundAlt="'reference sheet examples background'"
        :images="refData"
      />
    </div>
    <div class="g6">
      <ImageWide
        :image="'ref_design'"
        :alt="'Image of reference sheets example'"
      />
    </div>
  </div>
</template>

<script>
import ImageWide from '@/components/ImageWide.vue'
import ImageSwiper from '@/components/ImageSwiper.vue'
import SheetExampleSwiper from '@/components/SheetExampleSwiper.vue'

export default {
  name: 'ContentSheets',

   components: {
        ImageWide, ImageSwiper, SheetExampleSwiper
    },

  data() {
    return {
      refData: [
        { filename: 'ref1', cleanImage: null, pricedImage: null, isActive: true, alt: 'swipable reference sheet example'},
        { filename: 'ref2', cleanImage: null, pricedImage: null, isActive: false, alt: 'swipable reference sheet example'},
        { filename: 'ref3', cleanImage: null, pricedImage: null, isActive: false, alt: 'swipable reference sheet example'},
        { filename: 'ref4', cleanImage: null, pricedImage: null, isActive: false, alt: 'swipable reference sheet example'},
        { filename: 'ref5', cleanImage: null, pricedImage: null, isActive: false, alt: 'swipable reference sheet example'},
      ],
    }
  },

  props: {

  },

  methods: {
    goto(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;
        window.scrollTo(0, top);
    }
  },
}
</script>


<style scoped>

.cs-main, .cs-g1, .cs-g2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.g1 {
  margin-top: 10%;
  position: relative;
}

.g1-button {
  position: absolute;
  top: 66.7408%;
  right: 9.2592%;
  width: 11.11111%;
  height: 5.8954%;
  border: 3px solid #ff84a5;
  border-radius: 10px;
  background-color: transparent;
  color: #ff84a5;
  transition: 0.2s ease-in-out;
}

.g1-button:hover {
  border: 3px solid #a576d7;
  color: #a576d7;
}

.g1-button:focus {
  border: 3px solid #a576d7;
  color: #ff84a5;
}

.g2, .g3, .g4, .g5 {
  margin-top: 3%;
}

.g5 {
  margin-bottom: 10%;
}

.g6 {
  margin-bottom: 10%;
}


@media  (width <= 760px) {
  .g1-button {
    border: 1px solid #ff84a5;
  }
}





</style>
