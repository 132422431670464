<template>
  <div class="app">
    <MainPage />
  </div>
</template>

<script>
import MainPage from '@/components/MainPage.vue';


export default {

    components: {
        MainPage
    },

    data() {
        return {

        }
    },

    methods: {
    
    }

   
    
}
</script>


<style>

:root {
  color-scheme: only light;
}

* {
    margin: 0px;
    padding: 0px;
}

img {
  display: block;
  width: 100%;
}

html, body {
    width: 100%;
    height: 100%;
}

body { 
    font-family: 'Dosis', sans-serif;
    background-image: linear-gradient(#ffedee, #c3dbf4);
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-y: scroll;  
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
}


@media (width >= 0px) and (width < 1080px) {
   .app {
    max-width: 100vw;
    min-width: 300px;
    font-size: 1rem;
  }
}

@media (width >= 1080px) and (width < 3840px) {
  .app {
    max-width: 42.18vw;
    min-width: 1080px;
    font-size: 2em;
  }
}

@media (width >= 3840px) and (width < 7680px) {
  .app {
    max-width: 1620px;
    min-width: 1080px;
    font-size: 2em;
  }
}



@media (width >= 300px) and (width < 640px) {
  .display-text {
    font-size: 5vw;
  }
}

@media  (width >= 640px) and (width < 1080px) {
  .display-text {
    font-size: 5vw
  }
}

@media (width >= 1080px) and (width < 3840px) {
  .display-text {
    font-size: clamp(2em, 4vw, 2.25em)
  }
}

@media (width >= 3840px) and (width < 7680px) {
  .display-text {
    font-size: 2.25em;
  }
}





@media (width >= 300px) and (width < 420px) {
  .button-text {
    font-size: 0.35em;
  }
}

@media  (width >= 420px) and (width < 640px) {
  .button-text {
    font-size: 0.45em;
  }
}

@media  (width >= 640px) and (width < 760px) {
  .button-text {
    font-size: 0.55em;
  }
}

@media  (width >= 760px) and (width < 880px) {
  .button-text {
    font-size: 0.70em;
  }
}

@media  (width >= 880px) and (width < 1080px) {
  .button-text {
    font-size: 0.80em;
  }
}

@media (width >= 1080px) and (width < 2560px) {
  .button-text {
    font-size: 0.65em;
  }
}

@media (width >= 2560px) and (width < 3200px) {
  .button-text {
    font-size: 0.62em;
  }
}

@media (width >= 3200px) and (width < 3840px) {
  .button-text {
    font-size: 0.75em;
  }
}

@media (width >= 3840px) and (width < 7680px) {
  .button-text {
    font-size: 0.85em;
  }
}

</style>
