<template>
  <div class="container" @click="OpenLink(link)">
    <img class="idle" :src="require(`@/assets/${image + '_idle.webp'}`)" :alt="'Clickable idle icon of ' + image + ' link'">
    <img class="colored" :src="require(`@/assets/${image + '_colored.webp'}`)" :alt="'Clickable colored icon of ' + image + ' link'">
  </div>
</template>

<script>


export default {
  name: 'IconHover',

   components: {
        
    },

  data() {
    return {

    }
  },

  props: {
    image: String,
    link: String,

  },

  methods: {
   OpenLink(link) {
    window.open(link, '_blank');
   }
  },
}
</script>


<style scoped>

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.idle {
  position: relative;
  opacity: 100;
}

.colored {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.4s ease-in-out;
  z-index: 10;
}

.colored:hover {
  opacity: 1;
}



</style>
