import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/info'
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../components/MainPage.vue')
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => import('../components/MainPage.vue')
  },
  {
    path: '/tos',
    name: 'tos',
    component: () => import('../components/MainPage.vue')
  },
  {
    path: '/forms',
    name: 'forms',
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/forms') {
    try {
      const response = await fetch('/forms.txt');
      if (response.ok) {
        const url = await response.text();
        window.location.href = url.trim(); // Trim to remove any whitespace
      } else {
        console.error('Failed to fetch the redirect URL.');
        next(false); // Cancel the navigation
      }
    } catch (error) {
      console.error('Error fetching the redirect URL:', error);
      next(false); // Cancel the navigation
    }
  } else {
    next();
  }
});



export default router
