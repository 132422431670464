import { createStore } from 'vuex'

export default createStore({
  state: {
    activeTab: 'info'
  },
  getters: {

  },
  mutations: {
    setActiveTab(state, tab) {
      state.activeTab = tab;
    },
  },
  actions: {
    updateActiveTab({ commit }, tab) {
      commit('setActiveTab', tab);
    }
  },
  modules: {

  }
})
