<template>
  <div>
    <Banner
      :image="'banner'"
      :alt="'banner'"
      @activeTabChanged="OnActiveTabChanged"
      :currentTab="currentTab"
    />

    <InfoMenu v-if="activeTab === 'info'"/>
    <ContentMenu  v-if="activeTab === 'prices'"/>
    <TosMenu v-if="activeTab === 'tos'"/>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Banner from '@/components/Banner.vue'
import InfoMenu from '@/components/InfoMenu.vue'
import ContentMenu from '@/components/ContentMenu.vue'
import TosMenu from '@/components/TosMenu.vue'


export default {
  name: 'MainPage',

  components: {
    Banner, InfoMenu, ContentMenu, TosMenu
  },

  data() {
    return {
      currentTab: 'info'
    }
  },

  computed: {
    ...mapState(['activeTab']),
  },

  methods: {
    ...mapActions(['updateActiveTab']),

    setTab(tab) {
      this.tab = tab;
      this.updateActiveTab(tab);
      this.$router.push({ name: tab });
    },

    OnActiveTabChanged(tab) {
      this.setTab(tab);
    }

  },

  watch: {
    $route(to) {
      this.updateActiveTab(to.name);
      this.currentTab = to.name;
    },
  },

  created() {
    const initialTab = this.$route.name || 'info';
    this.updateActiveTab(initialTab);
    this.currentTab = initialTab;
  },


}
</script>


<style scoped>

.cardset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.cardsetleft {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cardsetright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

</style>
