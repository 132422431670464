<template>
  <div class="si-main">
      <img :src="require(`@/assets/${image}.webp`)" :alt="alt">
  </div>
</template>

<script>
export default {
  name: 'WideImage',

  props: {
    image: String,
    alt: String,
  }
}
</script>


<style scoped>

.si-main {
  width: 100%;
}

</style>
